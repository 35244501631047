import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderBackIconButton, HeaderHelpChip } from '../components';

export type HeaderWithBackAndHelpProps = Omit<
  BaseHeaderProps,
  'leftElement' | 'rightElement'
> & {
  onHelpClick?: () => void;
  onBackClick?: () => void;
};

export function HeaderWithBackAndHelp({
  onHelpClick,
  onBackClick,
  ...props
}: Readonly<HeaderWithBackAndHelpProps>) {
  return (
    <BaseHeader
      {...props}
      leftElement={<HeaderBackIconButton onClick={onBackClick} />}
      rightElement={<HeaderHelpChip onClick={onHelpClick} />}
    />
  );
}
